.people-icon{
    transform: translateY(2px);
}
.card_data{
    font-weight: 600;
    margin-bottom: 8px;
}
.icons_mb{
    margin-bottom: 6px;
}
.click_icon_edit{
    font-size: 20px;
}
.click_icon_edit{
    font-size: 20px;
}
.attendance-card{
    padding: 12px;
    box-shadow: 0 0 8px 2px rgba(119, 119, 119, 0.15);
    border-radius: 15px;
      
}
.filial-card{
    margin-bottom: 20px;
    border-bottom: 2px solid #f5f5f5;
    padding-bottom: 10px;

}
.add_attendance{
    padding: 12px;
    border-radius: 15px;
    border: solid #09C9ED 2px;
    color: #09C9ED;
    background: transparent;
}
.add-form{
    padding: 12px;
    border-radius: 15px;
    border: solid #09C9ED 2px;
    color: #09C9ED;
    background: transparent;
}
.add_dnevnik_input{
    padding: 0px;
    margin: 0;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px ;
    border-radius: 10px;
    border: solid #979a9b;
}
.prepod_name{
    font-size: 10px;
}