.pm_modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    height: 100%;
    background: rgba(0, 0, 0, 0.73);
}

.modal-content {
    margin-left: 10%;
    background-color: #fefefe;
    margin: auto;
    margin-top: 200px;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 5px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
