.body{
    position: relative;
}
.wrapper{
    margin: auto;
}
.header_cont{
    padding-top: 20px;
    padding-bottom: 20px;
}
.gridx{
    display: grid;
    align-items: start;
}
.gridxin{
    display: grid;
}
.grid3{
    display: grid;
    grid-gap: 20px;
}
.flex{
    display: flex;
}
.al_itms_c{
    align-items: center;
}

.dnevnik-filial-card{
    display: grid;
    width: 100%;
    grid-gap: 20px;
  }

.header_gaps{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.menu_header{
    display: flex;
}
.analytic_select_cont{
    width: 100%;
    max-width: 300px;
    display: flex;
    gap: 10px;
}
/* Стили для мобильных устройств (до 767px) */
@media (max-width: 767px) {
    .wrapper{
        width: 95%;
    }
}

/* Стили для планшетов (768px до 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .wrapper{
        width: 95%;
    }
}

/* Стили для компьютеров (более 1024px) */
@media (min-width: 1025px) {
    .wrapper{
        max-width: 1400px;
        width: 90%;
    }
    .gridx{
        grid-template-columns: 1fr 2fr;
        grid-gap: 20px;
    }
    .gridxin{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10px;
    }
    .grid3{
        grid-template-columns: repeat(3,1fr);
        grid-gap: 30px;
    }
    .dnevnik-filial-card{
        grid-template-columns: 1fr 3fr 1fr;
    }
   
}


.mt20{
    margin-top: 20px;
}


.mb15{
    margin-bottom: 15px;
}
.mla{
    margin-left: auto;
}
.ml5{
    margin-left: 5px;
}
.ml15{
    margin-left: 15px;
}

.pd20{
    padding: 20px;
}
.pd5{
    padding: 5px;
}

