/* PayInfo.css */

.pagination {
    display: flex;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover {
    background-color: #e0e0e0;
}

.pagination .active {
    background: #09C9ED;
    color: #fff;
    border-color: #09C9ED;
}
